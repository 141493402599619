<template>
  <v-container class="pa-4">
    <Substipulator
      :contractId="$route.params.id"
      @sub="subSelected($event)"
      v-if="hasSub"
    />

    <v-form
      @submit.prevent="save"
      ref="form"
      autocomplete="off"
      lazy-validation
      v-model="valid"
    >
      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <h5 class="mb-4">Dados de acesso na operadora</h5>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-text-field
            label="Login"
            id="login"
            name="login"
            autocomplete="off"
            outlined
            dense
            required
            :rules="[required]"
            v-model="model.login"
          />
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <BasePassword
            label="Senha"
            id="password"
            name="password"
            autocomplete="off"
            dense
            outlined
            required
            :rules="[required]"
            v-model="model.password"
          />
        </v-col>
      </v-row>

      <v-divider class="my-5" />

      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-right">
          <BaseButton
            id="btn-back"
            name="btn-back"
            title="Voltar"
            color="primary"
            outlined
            @click="back"
          />

          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
            v-permission="{
              roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
              operator: '==='
            }"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  components: {
    Substipulator: () => import('./commons/substipulator')
  },

  props: {
    productType: {
      type: String
    },
    customerId: {
      type: String
    },
    hasSub: {
      type: Boolean
    }
  },

  data: () => ({
    substipulator: null,
    valid: true,
    model: {
      id: null,
      login: null,
      password: null,
      insuranceComapanyName: null
    }
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      try {
        const contractService = new ContractService();
        const { status, data } = await contractService.getContractAccessData({
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId
        });

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const contractService = new ContractService();
        const { status } = await contractService.postContractAccessData({
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId,
          ...this.model
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    },

    subSelected(event) {
      this.substipulator = event;
      this.search();
    }
  }
};
</script>
